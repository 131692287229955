<template>
    <page-title
        icon="bi bi-signpost-2"
        title="Dashboard"
    >
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <section class="section" v-else>
        <h2 class="section-title">お知らせ</h2>
        <template v-if="informations.length">
            <div class="card mb-2" v-for="information in informations" :key="information.information_id">
                <div class="card-header d-flex justify-content-between">
                    <span class="fw-bold">{{ information.title }}</span>
                    <span>{{ information.information_datetime_display }}</span>
                </div>
                <div class="card-body">
                    <p class="content">{{ information.content }}</p>
                    <!-- <footer class="blockquote-footer">{{ information.author }}</footer> -->
                </div>
            </div>
            <paginator :meta="paginator" @move="fetchInformations"></paginator>
        </template>
        <alert-no-record text="お知らせはありません" v-else></alert-no-record>
    </section>

</template>

<script>
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Paginator from '@/components/tools/Paginator';
import PageTitle from '@/components/PageTitle.vue';
import Information from '@/models/entities/information';

export default {
    name: 'Home',
    components: {
        AlertNoRecord,
        InlineLoader,
        Paginator,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            // 部分ローダー
            loading: 0,

            informations: [],

            paginator: {},
        }
    },
    mounted() {
        //お知らせ取得
        this.fetchInformations();
    },
    methods: {
        //製品グループリビジョンを取得
        fetchInformations(page = 1) {
            this.loading++;
            this.informations.splice(0); //初期化

            this.$http.get(`/information`, {
                params: {
                    page: page,
                }
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.informations.push(new Information(row));
                }
                //ページネーション用
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.loading--;
            });
        }
    },
}
</script>

<style scoped>
    .content {white-space: pre-wrap; word-break: break-all;}
</style>
