/**
 * お知らせ エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdHi } from '@/utilities/date-format';

class Information {
    Information_id;
    information_datetime;
    title;
    content;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.Information_id = properties.Information_id;
        this.information_datetime = properties.information_datetime;
        this.title = properties.title;
        this.content = properties.content;
    }

    get information_datetime_display() {
        return dateYmdHi(this.information_datetime);
    }
}

export default Information;
